import axios from "axios";

import { getParsedOrderV6 } from "./postDataParser";

const getProductNumber = (number) => {
  if (number.endsWith("N")) {
    return number;
  }

  return number + "N";
};

export const getToken = async () => {
  if (localStorage.getItem("token")) {
    const oldData = JSON.parse(localStorage.getItem("token"));
    if (
      new Date().getTime() - new Date(oldData.time).getTime() <
      oldData.expires_in
    ) {
      return oldData.access_token;
    }
  }

  const { data } = await axios.post(`${process.env.REACT_APP_SHOPWARE_API}/oauth/token`, {
    /* live */
    grant_type: "client_credentials",
    client_id: "SWUAMXRFQK12T1AYR0JQC29UTA",
    client_secret: "VU04N1gxczhnRHNTODBOaGxNckVhY1o3c01adzh4UU1qTDV1dTU",

    /* stage1 */
    // grant_type: "client_credentials",
    // client_id: "SWUANMZIENG2WUTNZVJJCHVVWQ",
    // client_secret: "aGw4UXdSeU14Y3plMmZtOExRUUFhOURCMkRjTjJmRjNmVzVNRUc",

  });

  localStorage.setItem("token", JSON.stringify({ ...data, time: new Date() }));
  return data.access_token;
};

export const getProducts = async () => {
  const token = await getToken();
  const { data } = await axios.get(`${process.env.REACT_APP_SHOPWARE_API}/product`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data.data;
};

export const getName = async (products, orderItem) => {
  const product = products.filter((a) => a.productNumber === orderItem)[0];
  const name = product ? product.name : `NO_NAME`;
  return name;
};

const getAddress = async (id) => {
  const token = await getToken();
  const { data } = await axios.get(`${process.env.REACT_APP_SHOPWARE_API}/customer-address/${id}`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.data;
};

const fetchOptions = async (id) => {
  const token = await getToken();
  const { data } = await axios.get(`${process.env.REACT_APP_SHOPWARE_API}/product/${id}/options`, {
    headers: { Authorization: `Bearer ${token}` },
  });

  return data.data;
}

export const postData = async (csvData, prodcuts, emailValue) => {
  if (csvData.length === 0) {
    return "";
  }
  const data = csvData[0];
  const orderNumber = data["Order"];
  const deliveryDate = data["Delivery Date"];

  const customer = await getCustomer(emailValue);

  const address = await getAddress(customer.defaultShippingAddressId);
  const productsWithCSV = [];
  const countryData = {
    AT: {
      taxId: "018ad12f18c072b09b5565d801f600bf",
      shippingMethodId: "019034d7dbbf711a9b3bc39e76fc7194",
      taxRate: 20,
      taxFree: "tax-free",
    },
    DE: {
      taxId: "018ad12f18c072b09b5565d801f600bf",
      shippingMethodId: "018ad1acd4fa726bb25aa7e7aad99814",
      taxRate: 19,
      taxFree: "net",
    }
  }
  let TaxShipping = {};
  let totalPrice = 0;
  for (let index = 0; index < csvData.length; index++) {
    const data = csvData[index];
    // const TaxShipping = countryData[data.Country];
    TaxShipping = countryData[data.Country];
    const prod = prodcuts.find(
      (p) => p.productNumber === getProductNumber(data["Order Item ID"])
    );
    if (prod) {
      const options = (await fetchOptions(prod.id)).map(option => ({ group: null, option: option.name }));
      productsWithCSV.push({ ...prod, CSV: data, options, TaxShipping });
      totalPrice += parseFloat(data["Price per Item"]) * parseFloat(data["Quantity"]);
    }
  }
  const order = getParsedOrderV6(
    customer,
    productsWithCSV,
    address,
    orderNumber.replace("/", "-"),
    totalPrice.toFixed(2),
    deliveryDate,
    TaxShipping
  );
  const token = await getToken();
  const result = await axios.post(`${process.env.REACT_APP_SHOPWARE_API}/order`, order, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return result;
};

export const getCustomer = async (email) => {
  const token = await getToken();
  const { data } = await axios.post(
    `${process.env.REACT_APP_SHOPWARE_API}/search/customer/`,
    { filter: [{ type: "equals", field: "email", value: email }] },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return data.data[0];
};
