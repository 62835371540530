/**
 * Parses EDI .txt file in goal to fetch order data.
 */
import { getName } from "./apiClient";

/**
 * Carrier for all orders.
 *
 * @type {string}
 */

const CARRIER = "dhl-germany";

const removeAdditionalSpaces = (lines) =>
    lines.map((line) => {
      let fullLine = "";

      for (let i = 0; i < line.length; i++) {
        fullLine += " " + line[i];
      }

      return fullLine.replace(/  +/g, " ").trim();
    });

const fetchDates = (ediFileLines) => {
  let dates = [];

  for (let i = 0; i < ediFileLines.length; i++) {
    let foundDates = ediFileLines[i].match(/\d{2}[.]\d{2}[.]\d{4}/g);

    if (foundDates) {
      let foundDate = foundDates[0];
      foundDate = foundDate.replaceAll(".", "-");
      dates.push(foundDate);
    }
  }

  return dates;
};

export const run = async (ediFileLines, products) => {
  ediFileLines = removeAdditionalSpaces(ediFileLines);
  ediFileLines = ediFileLines.filter((ediFileLine) => ediFileLine !== "");

  let company = ediFileLines.filter((ediFileLine) =>
      ediFileLine.match(/Baby|baby.\sGmbH/g)
  )[0];
  let address = "";
  let correctId = 3;

  if (!ediFileLines[3].match(/\w+\s\w+\s\d+/g)) {
    let companyBefore = ediFileLines[2] + " " + ediFileLines[3];
    let companyReRes = companyBefore.match(/(\w+|\d+|\s) GmbH/g);

    if (companyReRes && !company.includes("GmbH")) {
      company = company + " " + companyReRes[0];
    }

    correctId = 4;
  }

  if (company.match(/&$/g)) {
    company += " " + ediFileLines[correctId - 1];
  }

  if (!address) {
    address = ediFileLines[correctId].match(/\w{2}\s\d{5}\s.+/g)
        ? ediFileLines[correctId - 1]
        : ediFileLines[correctId];
  }

  let filtedCountryData = ediFileLines.filter((ediFileLine) =>
      ediFileLine.match(/\w{2}\s\d{5}\s.+/g)
  )[0];
  // Since the zipcode for AT is 4 digits first it checks if it exists, if not, it proceeds to DE matching which have 5 digits
  let countryData = filtedCountryData.match(/\w{2}\s\d{4}\s.+/g)
      ? filtedCountryData.match(/\w{2}\s\d{4}\s.+/g)
      : filtedCountryData.match(/\w{2}\s\d{5}\s.+/g);

  let countryCodePostCodeCity = countryData[0].split(" "),
      countryCode = countryCodePostCodeCity[0],
      postCode = countryCodePostCodeCity[1],
      city =
          countryCodePostCodeCity.length === 4 &&
          countryCodePostCodeCity[3].length > 2
              ? countryCodePostCodeCity[2] + " " + countryCodePostCodeCity[3]
              : countryCodePostCodeCity[2],
      [orderDate, deliveryDate] = fetchDates(ediFileLines),
      address2 = ediFileLines
          .filter((ediFileLine) => ediFileLine.includes("Filiale:"))[0]
          .match(/Filiale:\s\d+/g)[0],
      orderCode = ediFileLines
          .filter((editFileLine) => editFileLine.match(/\d+[/]\d+/g))[0]
          .match(/\d+[/]\d+/g)[0];

  const res = await fetchOrderItems(ediFileLines, products);
  const formattedDeliveryDate = deliveryDate.replace(/-/g, ".");
  const data = res.map((orderItem) => ({
    "Tracking Numbers": "",
    Carrier: CARRIER,
    Order: orderCode,
    "Order Date": orderDate,
    "Supplier SKU": orderItem["Order Item ID"],
    "Westwing SKU": "",
    "Product Name": orderItem["Product Name"],
    Quantity: orderItem["Quantity"],
    "Delivery Date": formattedDeliveryDate,
    "Last Name": "",
    "First Name": "",
    Company: company,
    Address: address,
    "Address 2": address2,
    "Post Code": postCode,
    City: city,
    Country: countryCode,
    Status: "",
    "Purchase Order": "",
    "Order Item ID": orderItem["Order Item ID"],
    "Internal Order Item ID": orderItem["Internal Order Item ID"],
    "Delivery Information": "",
    "Price per Item": orderItem["Price per Item"],
  }));
  return data;
};

const fetchOrderItems = async (ediFileLines, products) => {
  let orderItems = [],
      foundBeginningOfOrderItems = false;

  for (let i = 22; i < ediFileLines.length; i++) {
    if (
        ediFileLines[i].includes("-") &&
        ediFileLines[i + 1] ===
        "Art.-Nr.Lieferant Artikelbezeichnung Bestellmenge (Stück)" &&
        ediFileLines[i + 2] === "Art.-Nr. EK-Preis EUR"
    ) {
      foundBeginningOfOrderItems = true;
      i += 2;
      continue;
    }

    if (
        foundBeginningOfOrderItems &&
        ediFileLines[i + 1]?.includes("*** Seite ")
    ) {
      foundBeginningOfOrderItems = false;
      continue;
    }

    if (
        foundBeginningOfOrderItems &&
        ediFileLines[i + 1]?.includes("Auftragswert") &&
        ediFileLines[i + 2]?.includes("Auftragsmenge") &&
        ediFileLines[i + 5]?.includes("*** Ende des Dokuments")
    ) {
      break;
    }

    // Check for the new lines that need to be skipped
    if (
        foundBeginningOfOrderItems &&
        ediFileLines[i]?.includes("Wir bitten um schriftliche Auftragsbestätigung. Vielen Dank.") &&
        ediFileLines[i + 1]?.includes("Mit freundlichem Gruß")
    ) {
      i += 2; // Skip the next two lines
      continue;
    }

    if (foundBeginningOfOrderItems && ediFileLines[i].includes("-")) {
      let itemDetails = ediFileLines[i + 1].split(" "),
          orderItemId = itemDetails[0],
          quantity;
      if (!ediFileLines[i + 2]) continue;
      const arr = ediFileLines[i + 2].split(" ");
      const len = arr.length;

      const internalOrderItemId = arr[0];
      const firstPartOfPrice = arr[len - 2],
          secondPartOfPrice = arr[len - 1];

      let pricePerItem =
          firstPartOfPrice + "." + secondPartOfPrice.substring(0, 2);

      for (let j = 1; j < itemDetails.length; j++) {
        if (j === itemDetails.length - 2) {
          quantity = itemDetails[j];
        }
      }

      const productName = await getName(products, orderItemId);
      orderItems.push({
        "Order Item ID": orderItemId,
        "Product Name": productName.trim(),
        Quantity: quantity,
        "Price per Item": pricePerItem,
        "Internal Order Item ID": internalOrderItemId,
      });

      i += 2;

      // Check for the new lines that need to be skipped
      if (
          foundBeginningOfOrderItems &&
          ediFileLines[i]?.includes("Wir bitten um schriftliche Auftragsbestätigung. Vielen Dank.") &&
          ediFileLines[i + 1]?.includes("Mit freundlichem Gruß")
      ) {
        i += 2; // Skip the next two lines
        continue;
      }

    }

  }

  return orderItems;
};