import React, { useState } from 'react';

import Styles from './styles.module.css';
import "@shopware-ag/meteor-component-library/dist/style.css";
import Loader from '../../assets/svg/loader.svg';

/**
 * Component responsible for import CSV file.
 *
 * @param files
 * @param handleCancel
 * @param handleConvertToCSV
 * @returns {JSX.Element|null}
 * @constructor
 */
const EDICsvImport = ({ files, handleCancel, handleConvertEdiToCsv }) => {
    const [loading, setLoading] = useState(false);

    const onFinish = () => setLoading(false)

    const onConvertEditToCSV = async () => {
        setLoading(true);
        handleConvertEdiToCsv(onFinish);
    }

    return (
        files.length > 0 ?
            <div className={Styles.wrapper}>
                <div className={Styles.uploadContent}>
                    <p>Erfolgreich! Folgende Datei wurde hochgeladen:</p>
                    {
                        files.map(uploadedFile => <p key={uploadedFile.name}>{uploadedFile.name}</p>)
                    }
                    <p>Was möchtest du jetzt tun?</p>
                    <div className={Styles.buttonWrapper}>
                        <button className={Styles.cancelButton} onClick={handleCancel}>abbrechen</button>
                        <button className={Styles.addButton} onClick={onConvertEditToCSV}
                            disabled={loading}
                        >Bestellung hinzufügen</button>
                        {loading && <img src={Loader} width={40} height={40} />}
                    </div>
                    {loading && <div style={{ marginTop: 10 }}>Bestellung wird verarbeitet.</div>}
                </div>
            </div> : null
    );
}

export default EDICsvImport;
