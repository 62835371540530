import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import EDIDropzone from '../components/EDIDropzone';
import EDICsvImport from '../components/EDICsvImport';
import CSVGenerator from '../utilities/CSVGenerator';

/**
 * Imports orders from EDI .txt files to .csv file.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const EDIOrdersImport = () => {
    /**
     * Files uploaded by user.
     */
    const [ files, setFiles ] = useState([]);

    /**
     * Fetches files from dropzone.
     *
     * @type {function(*=): void}
     */
    const onDrop = useCallback(acceptedFiles => {
        setFiles(acceptedFiles);
    }, []);

    /**
     * Dropzone methods.
     */
    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

    /**
     * Handles canceling after loading EDI .txt files.
     */
    const handleCancel = () => {
        setFiles([]);
    }

    /**
     * Handles converting from EDI .txt files to .csv file.
     */
    const handleConvertEdiToCsv = async (onFinish) => {
        CSVGenerator.run(files, onFinish);
    }

    return (
        <>
            <EDIDropzone getRootProps={getRootProps} getInputProps={getInputProps} isDragActive={isDragActive} />
            <EDICsvImport files={files} handleCancel={handleCancel} handleConvertEdiToCsv={handleConvertEdiToCsv} />
        </>
    )
}

export default EDIOrdersImport;
