import React from 'react';
import EDIOrdersImport from './containers/EDIOrdersImport';

/**
 * Application which imports order from EDI .txt file to .csv file.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const App = () => <EDIOrdersImport />;

export default App;
