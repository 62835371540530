import Papa from "papaparse";
import { getProducts, postData } from "./apiClient";
import { run } from "./EDIParser";

/**
 * Generates CSV file.
 */
class CSVGenerator {
  /**
   * Encoding for parsed file.
   *
   * @type {string}
   */
  static ENCODING = "ISO-8859-1";

  /**
   * Runs generating CSV file.
   *
   * @param files
   */
  run = (files, onFinish) => {
    getProducts().then((products) => {
      for (let i = 0; i < files.length; i++) {
        Papa.parse(files[i], {
          encoding: CSVGenerator.ENCODING,
          // eslint-disable-next-line no-loop-func
          complete: async (results) => {
            const data = results.data.find((item) => item[0].includes("Filiale:"));
            if (data) {
              const filiale = +data[0]?.split(":")?.[1]?.trim();
              const emailValue = 'babyone' + filiale + '@kindsgut.de';
              let oneCsvContent = await run(results.data, products);
              let fileName = "edi-orders-" + Date.now() + ".csv";

              let csvData = Papa.unparse(oneCsvContent);
              const res = await postData(oneCsvContent, products, emailValue);
              if (res) {
                this.executeDownloadCsvFile(csvData, fileName);
                onFinish();
              }
            }
          },
        });
      }
    });
  };

  /**
   * Executes download CSV file.
   *
   * @desc generates file, browser will start dowloading
   * @param data (ex: csv file), filename (string), type (string)
   */
  executeDownloadCsvFile = (
    data,
    filename = "export.csv",
    type = "text/csv;charset=utf-8;"
  ) => {
    let blob = new Blob(["\uFEFF" + data], { type: type });

    if (navigator && navigator.msSaveBlob) {
      // Edge, IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      let link = document.createElement("a");

      if (link.download !== undefined && URL.createObjectURL) {
        // feature detection
        // Browsers that support HTML5 download attribute
        let url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };
}

const CSVGeneratorInstance = new CSVGenerator();

export default CSVGeneratorInstance;
